import Head from "next/head";

import Footer from "../components/partials/Footer";

import "../styles/global.css";

export default function RestructAIWebsite({ Component, pageProps }) {
  return (
    <>
      <Head>
        <link rel="icon" href="/images/favicon.ico" />
        <title>RestructAI</title>
        <meta property="og:site_name" content="RestructAI" />
        <meta
          name="keywords"
          content="todo, task, list, plan, organise, productivity"
        />
        <meta
          property="og:image"
          content="https://www.restructai.tech/images/logo-complete.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      <Component {...pageProps} />
      <Footer />
    </>
  );
}
