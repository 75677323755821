import Image from "next/image";
import Link from "next/link";

import footerStyles from "../../styles/partials/footer.module.css";

export default function Footer() {
  return (
    <div className={"container-full " + footerStyles["container-full-content"]}>
      <div
        className={
          "container-partial " + footerStyles["container-partial-content"]
        }
      >
        <div>
          <div className={footerStyles["container-image"]}>
            <Link href="/" passHref legacyBehavior>
              <a>
                <Image
                  src={"/images/logo-complete.png"}
                  alt={"RestructAI Logo"}
                  layout="fill"
                  className={footerStyles["image"]}
                  draggable="false"
                  loading={"eager"}
                />
              </a>
            </Link>
          </div>
          <p className={footerStyles["copyright"]}>
            All rights reserved © {new Date().getFullYear()} RestructAI
          </p>
          <br />
          <p className={footerStyles["company-info"]}></p>
        </div>
        <div className={footerStyles["container-content"]}>
          <div>
            <h4>Resources</h4>
            <ul className={footerStyles["content-column-list"]}>
              <li className={footerStyles["content-column-list-element"]}>
                <Link
                  href={
                    "https://www.linkedin.com/company/restructuringrecoded/about/"
                  }
                  passHref
                  legacyBehavior
                >
                  <a>LinkedIn</a>
                </Link>
              </li>
            </ul>
          </div>
          {/* <div>
            <h4>Legal</h4>
            <ul className={footerStyles["content-column-list"]}>
              <li className={footerStyles["content-column-list-element"]}>
                <Link href="/terms" passHref legacyBehavior>
                  <a>Account </a>
                </Link>
              </li>
              <li className={footerStyles["content-column-list-element"]}>
                <Link href="/privacy" passHref legacyBehavior>
                  <a>Privacy </a>
                </Link>
              </li>
              <li className={footerStyles["content-column-list-element"]}>
                <Link href="/cookies" passHref legacyBehavior>
                  <a>Turn up and Pay </a>
                </Link>
              </li>
              <li className={footerStyles["content-column-list-element"]}>
                <Link href="/cookies" passHref legacyBehavior>
                  <a>Booking </a>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4>App</h4>
            <ul className={footerStyles["content-column-list"]}>
              <li className={footerStyles["content-column-list-element"]}>
                
              </li>
              <li className={footerStyles["content-column-list-element"]}>
              </li>
              <li className={footerStyles["content-column-list-element"]}>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
}
